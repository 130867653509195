import { render, staticRenderFns } from "./PersonalDataPanel.vue?vue&type=template&id=46016bde"
import script from "./PersonalDataPanel.vue?vue&type=script&lang=js"
export * from "./PersonalDataPanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanelContent})
